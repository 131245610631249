import React, { Suspense, lazy } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import ErrorBoundary from "_components/core/ErrorBoundary";
import { useSelector } from "react-redux";
import { ObjectTools } from "_utility";
import { importRetry } from "_helpers";
const { getNestedPropertySafe } = ObjectTools;

const NotFound = lazy(() => importRetry(() => import(/* webpackChunkName: "NotFound" */ "../_views/NotFound")));

const Dashboard = lazy(() => importRetry(() => import(/* webpackChunkName: "Dashboard" */ "../_views/Dashboard")));

const ResourceToolsKnowledgeBase = lazy(() =>
  importRetry(() =>
    import(/* webpackChunkName: "ResourceTools-KnowledgeBase" */ "../_views/ResourceTools/KnowledgeBase")
  )
);

const ResourceToolsFaq = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "ResourceTools-Faq" */ "../_views/ResourceTools/Faq"))
);

const ResourceToolsOPDClaimMap = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "ResourceTools-Faq" */ "../_views/ResourceTools/OPDClaimMap"))
);

const Profile = lazy(() => importRetry(() => import(/* webpackChunkName: "Profile" */ "../_views/Profile")));

const ReportList = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Reports" */ "../_views/Reports/CustomReports/ReportList"))
);

const ReportBuilder = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Reports" */ "../_views/Reports/CustomReports/ReportBuilder"))
);
const ReportViewer = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Reports" */ "../_views/Reports/CustomReports/ReportViewer"))
);

const LegacyReports = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Reports" */ "../_views/Reports/LegacyReports"))
);

const ClaimLetterContacts = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Reports" */ "../_views/Reports/ClaimLetter"))
);

const LetterReasons = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Reports" */ "../_views/Reports/LetterReasons"))
);

const RoundRobin = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "RoundRobin" */ "../_views/RoundRobin/RoundRobin"))
);

const LegalClaims = lazy(() => 
  importRetry(() => import(/* webpackChunkName: "LegalClaims" */ "../_views/LegalClaims"))
);

const Dispatch = lazy(() => importRetry(() => import(/* webpackChunkName: "Dispatch" */ "../_views/Dispatch")));
const DispatchAdmin = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "DispatchAdmin" */ "../_views/DispatchAdmin"))
);

const ClaimsQueue = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "ClaimsQueue" */ "../_views/ClaimsQueue/ClaimsQueue"))
);

const InternalMobileClaims = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "MobileClaims" */ "../_views/MobileClaims"))
);

const Ledger = lazy(() => importRetry(() => import(/* webpackChunkName: "Ledger" */ "../_views/Ledger")));

const DispatchForm = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Dispatch Form" */ "../_views/Dispatch/Form"))
);

const DispatchFormPowerGas = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Dispatch Form" */ "../_views/Dispatch/FormPowerGas"))
);

const MyTeam = lazy(() => importRetry(() => import(/* webpackChunkName: "Admin-MyTeam" */ "../_views/MyTeam")));

// const DispatchClaim = lazy(() =>
//   importRetry(() =>
//     import(
//       /* webpackChunkName: "Dispatch-Claim" */ "../_views/Dispatch/DispatchClaim"
//     )
//   )
// );

// Telecom
const ClaimsTelecom = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Claims-Telecom" */ "../_views/Claims/Lob/telecom/views/Claims"))
);

const ClaimFormTelecom = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Claims-Telecom-Form" */ "../_views/Claims/Lob/telecom/views/Claim"))
);

const StartClaimTelecom = lazy(() =>
  importRetry(() =>
    import(/* webpackChunkName: "Claims-Telecom-Start" */ "../_views/Claims/Lob/telecom/views/StartClaim")
  )
);

// PowerGas
const ClaimsPowerGas = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Claims-PowerGas" */ "../_views/Claims/Lob/powerGas/views/Claims"))
);

const ClaimFormPowerGas = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Claims-PowerGas-Form" */ "../_views/Claims/Lob/powerGas/views/Claim"))
);

const StartClaimPowerGas = lazy(() =>
  importRetry(() =>
    import(/* webpackChunkName: "Claims-PowerGas-Start" */ "../_views/Claims/Lob/powerGas/views/StartClaim")
  )
);

// Frelo
const ProjectsFrelo = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Claims-Frelo" */ "../_views/Claims/Lob/frelo/views/Projects"))
);

const ProjectFormFrelo = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Claims-Frelo-Form" */ "../_views/Claims/Lob/frelo/views/Project"))
);

const ProjectCreateFormFrelo = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Claims-Frelo-Form-Start" */ "../_views/Claims/Lob/frelo/views/Create"))
);

// Admin

const AdminEntityManagement = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-EntityManagement" */ "../_views/Admin/EntityManagement"))
);

const AdminEntityManagementClientManagement = lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "Admin-EntityManagement-ClientManagement" */ "../_views/Admin/EntityManagement/ClientManagement"
    )
  )
);

const AdminEntityManagementDocManagementInvoice = lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "Admin-EntityManagement-doc-management-invoice" */ "../_views/Admin/EntityManagement/ClientManagement/components/ClientTabs/components/DocMgmt/views/Invoice"
    )
  )
);

const AdminEntityManagementDocManagementClaim = lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "Admin-EntityManagement-doc-management-claim" */ "../_views/Admin/EntityManagement/ClientManagement/components/ClientTabs/components/DocMgmt/views/Claim"
    )
  )
);

const AdminEntityManagementBillingStructure = lazy(() =>
  importRetry(() =>
    import(
      /* webpackChunkName: "Admin-EntityManagement-BillingStructure" */ "../_views/Admin/EntityManagement/ClientManagement/components/ClientTabs/components/BillingTypes/view/BillingStructure"
    )
  )
);

const AdminUserManagement = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-UserManagement" */ "../_views/Admin/UserManagement"))
);

const AdminUserManagementUser = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-UserManagement-User" */ "../_views/Admin/UserManagement/User"))
);

const AdminRolesManagement = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-RolesManagement" */ "../_views/Admin/RolesManagement"))
);

const AdminRolesManagementRole = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-RolesManagement-Role" */ "../_views/Admin/RolesManagement/Role"))
);

const PowerBiAdminRolesManagement = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-RolesManagement" */ "../_views/Admin/PowerBiRolesManagement"))
);

const PowerBiAdminRolesManagementRole = lazy(() =>
  importRetry(() =>
    import(/* webpackChunkName: "Admin-RolesManagement-Role" */ "../_views/Admin/PowerBiRolesManagement/Role")
  )
);

const AdminNotifications = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-Notifications" */ "../_views/Admin/Notifications"))
);

const AdminNotification = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-Notification" */ "../_views/Admin/Notifications/Notification"))
);

const AdminRDAManagement = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-RDAManagement" */ "../_views/Admin/RDAManagement"))
);

const AdminKnowledgeBase = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-KnowledgeBase" */ "../_views/Admin/KnowledgeBase"))
);

const AdminTeamManagement = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-TeamManagement" */ "../_views/Admin/TeamManagement"))
);

const AdminGoalDashboard = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-GoalDashboard" */ "../_views/Admin/GoalDashboard"))
);

const AdminClaimStatus = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-ClaimStatus" */ "../_views/Admin/ClaimStatusManagement"))
);

const SmartSheet = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "SmartSheet" */ "../_views/Admin/SmartSheet"))
);

const LedgerDeletion = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Ledger-Deletion" */ "../_views/Admin/Ledgers"))
);

const StatusNoteDeletion = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Status-Note-Deletion" */ "../_views/Admin/StatusNotes"))
);

const ActionNoteDeletion = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-Action-Notes" */ "../_views/Admin/ActionNotes"))
);

const AdminFormControl = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-Form-Control" */ "../_views/Admin/FormControl"))
);

const AdminJobCosting = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-Job-Costing" */ "../_views/Admin/JobCosting"))
);

const AdminReceiptMaker = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Admin-Receipt-Maker" */ "../_views/Admin/ReceiptMaker"))
);

const AdminRemittance = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Status-Note-Deletion" */ "../_views/Admin/Remittance"))
);

// Mobile claims login
// const MobileStartClaimLogin = lazy(() =>
//   importRetry(() =>
//     import(/* webpackChunkName: "Mobile-Start-Claim-Charter" */ "../../App/MobileClaim/entity")
//   )
// );
// Mobile claims
const MobileStartClaimMyList = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Mobile-Start-Claim" */ "../../App/MobileClaim/view/myInvoices"))
);
const MobileStartClaim = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Mobile-Start-Claim" */ "../../App/MobileClaim/view/startInvoice"))
);
const MobileStartClaimUpdate = lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Mobile-Start-Claim" */ "../../App/MobileClaim/view/updateInvoice"))
);

// ----- Note ----
// Routes with params need to come before parents

// ---- Schema ----
// /entity/:lob/:id
// id = entityId
// lob = line of business

export default function Routes() {
  let { path } = useRouteMatch();

  const { loggedIn, loggingIn } = useSelector((state) => getNestedPropertySafe(state, ["auth"]));

  return (
    <ErrorBoundary>
      {!loggedIn && !loggingIn && <Redirect to="/" />}
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path={`${path}/admin/claim-status`} render={() => <AdminClaimStatus />} />
          <Route path={`${path}/admin/form-control`} render={() => <AdminFormControl />} />
          <Route path={`${path}/admin/job-costing`} render={() => <AdminJobCosting />} />
          <Route path={`${path}/admin/receipt-maker`} render={() => <AdminReceiptMaker />} />
          <Route path={`${path}/admin/remittance`} render={() => <AdminRemittance />} />
          <Route path={[`${path}/resource-tools/faqs`]} render={() => <ResourceToolsFaq />} />
          <Route path={[`${path}/resource-tools/opd-claim-map`]} render={() => <ResourceToolsOPDClaimMap />} />
          <Route path={[`${path}/resource-tools/knowledge-base`]} render={() => <ResourceToolsKnowledgeBase />} />
          <Route path={`${path}/my-team`} render={() => <MyTeam />} />

          <Route path={`${path}/claim/frelo/create`} render={() => <ProjectCreateFormFrelo />} />

          <Route path={`${path}/claim/power-gas/create`} render={() => <StartClaimPowerGas />} />

          <Route path={`${path}/claim/telecom/create`} render={() => <StartClaimTelecom />} />

          <Route path={[`${path}/project/frelo/:id`]} render={() => <ProjectFormFrelo />} />
          <Route
            path={`${path}/claim/power-gas/:id/access-denied/:reason`}
            render={() => <ClaimFormPowerGas accessDenied={true} />}
          />
          <Route path={`${path}/claim/power-gas/:id`} render={() => <ClaimFormPowerGas />} />
          <Route
            path={`${path}/claim/telecom/:id/access-denied/:reason`}
            render={() => <ClaimFormTelecom accessDenied={true} />}
          />
          <Route path={`${path}/claim/telecom/:id`} render={() => <ClaimFormTelecom />} />

          <Route path={`${path}/profile`} render={() => <Profile />} />

          <Route path={`${path}/project/frelo`} render={() => <ProjectsFrelo />} />

          <Route path={`${path}/claim/power-gas`} render={() => <ClaimsPowerGas />} />
          <Route path={`${path}/claim/telecom`} render={() => <ClaimsTelecom />} />

          <Route exact path={`${path}/reports/custom`} render={() => <ReportList />} />
          <Route path={`${path}/reports/custom/edit/:id`} render={() => <ReportBuilder />} />
          <Route path={`${path}/reports/custom/view/:id`} render={() => <ReportViewer />} />
          <Route path={`${path}/reports/legacy/:lob`} render={() => <LegacyReports />} />
          <Route path={`${path}/claims-manager/contacts`} render={() => <ClaimLetterContacts />} />
          <Route path={`${path}/claims-manager/letters`} render={() => <LetterReasons />} />
          <Route path={`${path}/round-robin`} render={() => <RoundRobin />} />
          <Route path={`${path}/legal-claims`} render={() => <LegalClaims />} />

          {/* <Route
            path={[
              `${path}/dispatch/telecom/:id`,
              `${path}/dispatch/frelo/:id`,
              `${path}/dispatch/power-gas/:id`,
              `${path}/dispatch/telecom`,
              `${path}/dispatch/frelo`,
              `${path}/dispatch/power-gas`,
            ]}
            render={() => <DispatchClaim />}
          /> */}

          <Route path={`${path}/dispatch/power-gas/:claimId/:dispatchAdmin`} render={() => <DispatchFormPowerGas />} />

          <Route path={`${path}/dispatch/power-gas/:claimId`} render={() => <DispatchFormPowerGas />} />

          <Route path={`${path}/dispatch/:lob/:claimId/:dispatchAdmin`} render={() => <DispatchForm />} />

          <Route path={`${path}/dispatch/:lob/:claimId`} render={() => <DispatchForm />} />

          <Route path={`${path}/dispatch/:lob`} render={() => <Dispatch />} />

          <Route path={`${path}/dispatch-admin/:lob`} render={() => <DispatchAdmin />} />

          <Route path={`${path}/ledger/:lob`} render={() => <Ledger />} />

          <Route path={`${path}/queues`} render={() => <ClaimsQueue />} />

          <Route path={`${path}/mobile-claims/:entityID/:mobileClaimID`} render={() => <InternalMobileClaims />} />
          <Route path={`${path}/mobile-claims`} render={() => <InternalMobileClaims />} />

          <Route path={`${path}/admin/team-management`} render={() => <AdminTeamManagement />} />
          <Route path={`${path}/admin/goals`} render={() => <AdminGoalDashboard />} />
          <Route path={`${path}/admin/users/:userId`} render={() => <AdminUserManagementUser />} />
          <Route path={`${path}/admin/users`} render={() => <AdminUserManagement />} />
          <Route path={`${path}/admin/knowledge-base`} render={() => <AdminKnowledgeBase />} />

          {/* here */}
          {/* @TODO: add permission */}

          <Route
            path={`${path}/admin/entity/:lob/:id/billing-structure/:billingId`}
            render={() => <AdminEntityManagementBillingStructure />}
          />

          <Route
            path={`${path}/admin/entity/:lob/:id/doc-management/invoice`}
            render={() => <AdminEntityManagementDocManagementInvoice />}
          />

          <Route
            path={`${path}/admin/entity/:lob/:id/doc-management/claim`}
            render={() => <AdminEntityManagementDocManagementClaim />}
          />

          {/*@TOOD: Check LOB child in Admin perm obj for access rights to :lob  targeted below*/}
          <Route path={`${path}/admin/entity/:lob/:id`} render={() => <AdminEntityManagementClientManagement />} />
          <Route path={`${path}/admin/entity`} render={() => <AdminEntityManagement />} />
          <Route path={`${path}/admin/roles/:roleId`} render={() => <AdminRolesManagementRole />} />
          <Route path={`${path}/admin/roles`} render={() => <AdminRolesManagement />} />
          <Route
            path={`${path}/admin/powerBiRoles/:powerBiRoleId`}
            render={() => <PowerBiAdminRolesManagementRole />}
          />
          <Route path={`${path}/admin/powerBiRoles`} render={() => <PowerBiAdminRolesManagement />} />
          {/* @TODO: still in use? if so add to perm object */}
          <Route path={`${path}/admin/rda`} render={() => <AdminRDAManagement />} />

          <Route path={`${path}/admin/notifications/:lob/:notificationId`} render={() => <AdminNotification />} />
          <Route path={`${path}/admin/notifications/create`} render={() => <AdminNotification />} />
          <Route path={`${path}/admin/notifications`} render={() => <AdminNotifications />} />

          <Route path={`${path}/damage-invoice`} render={() => <MobileStartClaim />} />

          <Route path={`${path}/admin/smartsheet`} render={() => <SmartSheet />} />
          <Route path={`${path}/admin/ledgers`} render={() => <LedgerDeletion />} />
          <Route path={`${path}/admin/status-notes`} render={() => <StatusNoteDeletion />} />
          <Route path={`${path}/admin/action-notes`} render={() => <ActionNoteDeletion />} />

          {/* Mobile Invoices */}
          <Route path={`${path}/mobile-claim/start-claim/`} render={() => <MobileStartClaim />} />
          <Route
            path={`${path}/mobile-claim/my-list/:entityId/:mobileClaimID`}
            render={() => <MobileStartClaimUpdate />}
          />
          <Route path={`${path}/mobile-claim/my-list/`} render={() => <MobileStartClaimMyList />} />
          {/* Mobile Entity Logins  */}
          {/* <Route
            path={`${path}/mobile-claim/`}
            render={() => <MobileStartClaimLogin />}
          /> */}

          {/* @TODO: setup permissions */}
          <Route path={`${path}`} render={() => <Dashboard />} />

          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}
